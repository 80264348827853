.logoCloudsSection {
	padding-top: 5vh;
	padding-bottom: 5vh;
	position: relative;
}

.progress {
	position: fixed;
	left: 0;
	right: 0;
	height: 5px;
	bottom: 100px;
}

.parallax {
	overflow: hidden;
	letter-spacing: -2px;
	line-height: 0.8;
	margin: 0;
	white-space: nowrap;
	display: flex;
	flex-wrap: nowrap;
}

.parallax .scroller {
	font-weight: 600;
	text-transform: uppercase;
	font-size: 64px;
	display: flex;
	white-space: nowrap;
	display: flex;
	flex-wrap: nowrap;
}

.parallax .logoClouds-spa {
	display: block;
	margin-right: 30px;
}
