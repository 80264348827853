.ml5 {
	position: relative;
	font-weight: 300;
	font-size: 1em;
	color: #ffffff;
}

.ml5 .text-wrapper {
	position: relative;
	display: inline-block;
	padding-top: 0.1em;
	padding-bottom: 0.1em;
}

.ml5 .line {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	height: 3px;
	width: 100%;
	background-color: #ffffff;
	transform-origin: 0.5 0;
}

/* .ml5 .ampersand {
	font-family: Baskerville, serif;
	font-style: italic;
	font-weight: 400;
	width: 1em;
	margin-right: -0.1em;
	margin-left: -0.1em;
} */

.ml5 .letters {
	display: inline-block;
	opacity: 0;
}

@media (max-width: 1025px) {
	.ml5 {
		font-size: 0.4em; /* Adjust the font size for smaller screens */
	}
}

@media (max-width: 640px) {
	.ml5 {
		font-size: 0.4em; /* Adjust the font size for smaller screens */
	}
}
