@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');
body {
	font-family: 'M PLUS Rounded 1c', sans-serif;
}

/* Or for specific elements */
h1,
h2,
h3,
p {
	font-family: 'M PLUS Rounded 1c', sans-serif;
}

.ppInput {
	display: none;
}

.custom-file-upload {
	margin: auto;
	width: 130px;
	height: 130px;
	border-radius: 50%;
	cursor: pointer;
	object-fit: cover;
	object-position: center center;
}

.custom-file-upload2 {
	margin: auto;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	cursor: pointer;
	object-fit: cover;
	object-position: center center;
}

.notifDrop {
	position: relative;
	z-index: 9999;
}

.bringBack {
	position: relative;
	z-index: -9999;
}
/* @media (max-width: 1700px) {
	.heroBringBack {
		position: relative;
		z-index: -9999;
	}
} */

/* .card1 {
	position: relative;
	z-index: 99999;
} */
