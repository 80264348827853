.card1 {
	width: 190px;
	height: 200px;
	/* background: rgb(103, 225, 255); */
	transition: all 0.4s;
	border-radius: 10px;
	box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.705);
	font-size: 30px;
	font-weight: 900;
}

.card1:hover {
	border-radius: 15px;
	cursor: pointer;
	transform: scale(1.2);
	box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.705);
	/* background: rgb(103, 151, 255); */
	cursor: default;
	pointer-events: auto;
}

.card1-first-content {
	height: 100%;
	width: 100%;
	transition: all 0.4s;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	border-radius: 15px;
}

.card1:hover .card1-first-content {
	height: 0px;
	opacity: 0;
}

.card1-second-content {
	height: 0%;
	width: 100%;
	opacity: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
	transition: all 0.4s;
	font-size: 0px;
	transform: rotate(90deg) scale(-1);
}

.card1:hover .card1-second-content {
	opacity: 1;
	height: 100%;
	font-size: 1.8rem;
	transform: rotate(0deg);
}

@media (max-width: 767px) {
	.card1 {
		width: 100px;
		height: 120px;
	}
	.home-svg-symbols {
		height: 60px;
		width: 60px;
	}
	.card1-second-content span {
		font-size: 15px;
	}
}
