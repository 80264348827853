.signin-google-button {
	float: right;
	max-width: 320px;
	display: flex;
	padding: 0.5rem 1.4rem;
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 700;
	text-align: center;
	font-family: 'Montserrat', sans-serif;
	vertical-align: middle;
	align-items: center;
	border-radius: 0.5rem;
	border: 1px solid rgba(255, 255, 255, 0.25);
	gap: 0.75rem;
	color: #c4d2dc;
	background-color: #19242b;
	cursor: pointer;
	transition: all 0.25s cubic-bezier(0, 0.87, 0.12, 1);
}

.signin-google-button:hover {
	transform: scale(1.025);
}

.signin-google-button:active {
	transform: scale(0.975);
}

.signin-google-button .signin-svg {
	height: 24px;
	width: auto;
}
